import dayjs from 'dayjs'

export const getDaysLeft = (date: Date) => {
  return dayjs(date).diff(dayjs(), 'days')
}

export const getDaysLeftWithI10n = (date?: Date) => {
  if (!date) {
    return { days: undefined, i10n: undefined }
  }

  const days = getDaysLeft(date)
  let i10n = 'many'

  if (days === 1) {
    i10n = 'one'
  } else if (days === 0) {
    i10n = 'zero'
  } else if (days >= 2 && days < 5) {
    i10n = 'few'
  }

  return {
    days,
    i10n,
  }
}
