import React, { PureComponent } from 'react'
import classnames from 'classnames'
import Confetti from 'react-confetti'
import Emoji from 'components/Emoji'
import Translate from 'translations/Translate'

interface IProps {
  visible: boolean
  title: React.ReactNode | string
  description?: React.ReactNode | string
  onClose?: (e) => void
  loading?: boolean
  children?: any
  className?: string
  allowScroll?: boolean
  confetti?: boolean
  headingWithoutMargin?: boolean
  descriptionWithoutMargin?: boolean
  size?: 'small' | 'medium'
}

interface IState {
  confettiVisible: boolean
  width: number
  height: number
}

export default class StandardModal extends PureComponent<IProps> {
  divRef = React.createRef<HTMLDivElement>()

  state: IState = {
    confettiVisible: false,
    width: 0,
    height: 0,
  }

  handleEscClose = (e) => {
    if (e.keyCode === 27) {
      if (this.props.onClose) {
        this.props.onClose(e)
      }
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      if (!this.props.allowScroll) {
        document.body.classList.add('modal-visible')
      }

      document.addEventListener('keydown', this.handleEscClose, false)
    }

    if (this.divRef.current) {
      this.setState({
        width: this.divRef.current.clientWidth,
        height: this.divRef.current.clientHeight,
      })
    }

    if (this.props.visible && this.props.confetti) {
      this.setState({ confettiVisible: true })
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        if (this.divRef.current) {
          this.setState({
            width: this.divRef.current.clientWidth,
            height: this.divRef.current.clientHeight,
          })
        }

        if (!this.props.allowScroll) {
          document.body.classList.add('modal-visible')
        }

        document.addEventListener('keydown', this.handleEscClose, false)
      } else {
        if (!this.props.allowScroll) {
          document.body.classList.remove('modal-visible')
        }

        document.removeEventListener('keydown', this.handleEscClose, false)
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.allowScroll) {
      document.body.classList.remove('modal-visible')
    }

    document.removeEventListener('keydown', this.handleEscClose, false)
  }

  close = (e) => {
    e.preventDefault()

    if (this.props.onClose) {
      this.props.onClose(e)
    }
  }

  render() {
    const {
      title,
      description,
      children,
      className,
      descriptionWithoutMargin,
      headingWithoutMargin,
      loading,
      onClose,
    } = this.props
    const { width, height, confettiVisible } = this.state

    return (
      <div
        className={classnames('small-modal__wrapper', {
          [className]: className,
        })}
      >
        <div onClick={this.close} className="small-modal__backdrop" />

        <div className="small-modal" ref={this.divRef}>
          {confettiVisible && (
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={300}
              confettiSource={{ x: 0, y: -30, w: width, h: 0 }}
            />
          )}

          {onClose && (
            <div
              data-cy="modal-close-button"
              onClick={this.close}
              className="modal__header-close modal__header-close--absolute"
            >
              <span className="button__in">
                <span className="button__in-icon">+</span>
              </span>
            </div>
          )}

          {loading ? (
            <Translate>
              {(t) => (
                <div className="loader-fullscreen__content">
                  <div className="center">
                    <p className="paragraph paragraph--big loader-fullscreen__icon">
                      <Emoji>⏳</Emoji>
                    </p>
                    <p className="paragraph paragraph--big no-margin-bottom">
                      {t('common.loading')}...
                    </p>
                  </div>
                </div>
              )}
            </Translate>
          ) : (
            <>
              <div
                className={classnames('small-modal__header', {
                  'small-modal__header--without-margin': headingWithoutMargin,
                })}
              >
                <h2 className="center heading-2-5">
                  <Emoji>{title}</Emoji>
                </h2>
                {description && (
                  <p
                    className={classnames('paragraph center', {
                      'small-margin': descriptionWithoutMargin,
                    })}
                  >
                    {description}
                  </p>
                )}
              </div>
              {children}
            </>
          )}
        </div>
      </div>
    )
  }
}
