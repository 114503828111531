const actions = {
  submit: 'Potvrdit',
  send: 'Odeslat',
  pay: 'Dokončit a zaplatit',
  edit: 'Upravit',
  open: 'Otevřít',
  top: 'Topovat',
  extend: 'Prodloužit',
  publish: 'Publikovat',
  duplicate: 'Duplikovat',
  save: 'Uložit',
  saveAndClose: 'Uložit a zavřít',
  loadMore: 'Načíst více',
  hide: 'Skrýt',
  show: 'Zobrazit',
  select: 'Vybrat',
  selectOneJob: 'Vybrat 1 nabídku',
  conclude: 'Uzavřít',
  pause: 'Pozastavit',
  login: 'Přihlásit',
  logout: 'Odhlásit',
  add: 'Přidat',
  close: 'Zavřít',
  cancel: 'Zrušit',
  showMore: 'Zobrazit více',
  signUp: 'Zaregistrovat',
  finishLater: 'Dokončit později',
  delete: 'Vymazat',
}

const error = {
  invalid: 'Neplatný',
  wrongFormat: 'Chybný formát',
  selectAtLeastOneOption: 'Vyberte alespoň 1 možnost',
  invalidURL: 'Neplatný formát URL adresy',
  invalidData: 'Neplatné údaje',
  wrongEmailOrPassword: 'Ups! Byl zadán nesprávný e-mail nebo heslo.',
  emailExists: 'Zadaný e-mail je již registrován.',
  invalidFormat: 'Nesprávný formát',
  invalidEmail: 'Neplatný email',
  invalidPhone: 'Neplatné číslo',
  errorWhileLogingIn: 'Při přihlašování nastala chyba',
  somethingWenWrongTrySendFormAgain:
    'Ups! Něco se pokazilo. Prosím, zkuste formulář odeslat znovu.',
  sendingError: 'Ups! Při odesílání nastala chyba.',
  errorDuringSaving: 'Při ukládání změn nastala chyba',
  errorDuringProcessingTryAgain:
    'Při zpracování nastala chyba. Prosím, zkuste akci opakovat.',
  errorDuringLoadingTryAgain: 'Při načítání nastala chyba. Zkuste znovu načíst stránku.',
  unknownErrorTrySendAgain: 'Neznámá chyba, prosím, zkuste odeslat znovu.',
  errorCheckAllRequiredField: 'Chyba! Prosím, zkontrolujte všechna povinná pole.',
  fillAllRequiredFormFields: 'Ups! Vyplňte všechna povinná pole formuláře.',
  registrationError: 'Ups! Při registraci nastala chyba.',
  savingError: 'Ups! Při ukládání nastala chyba.',
  addingJobError: 'Ups! Při přidávání jobu nastala chyba.',
  unknownErrorTryAgain: 'Ups! Neznámá chyba. Prosím, zkuste znovu.',
  uploadFileError: 'Vyskytla se chyba při nahrávání souboru.',
  maxFileSizeExceeded: 'Byla překročena maximální velikost souboru.',
  minFileDimensionExceeded: 'Obrázek má příliš malý rozměr.',
  insertPdfFile: 'Prosím, vložte soubor ve formátu .pdf.',
  insertDocument: 'Prosím, vložte soubor ve formátu .pdf, .doc nebo .docx.',
  selectedFileIsBroken:
    'Vybraný soubor je poškozený. Zkontrolujte ještě jednou svůj výběr.',
  somethingWentWrongTryAgainOrContactUs:
    'Ups! Něco se pokazilo. Opakujte požadavek nebo nás prosím kontaktujte.',
  somethingWentWrongPleaseRefresh: 'Ups! Něco se pokazilo. Zkuste aktualizovat stránku.',
  pleaseRefresh: 'Ups! Prosím skúste refresh.',
}

const tooltip = {
  countryInputDisabled: 'Pro změnu země prosím nejprve odstraňte město.',
  photoUploadTip:
    'Tip: Môžete použiť rovnaké logo ako máte nahraté na facebook stránke vašej firmy. Stačí si ho stiahnuť a potom nahrať sem kliknutím na tlačidlo Vybrať.',
  photoUploadFormat: `
    Vložte soubor ve formátu <strong>.jpg, .png, .gif.</strong>. Maximální velikost
    je {{maxSize}}Mb.<br /> 
    Doporučené rozměry {{dimension}}x{{dimension}}px.`,
  photoFreelancerUploadFormat: `
    Vložte soubor ve formátu <strong>.jpg, .png, .gif.</strong>. Maximální velikost
    je {{maxSize}}Mb.<br /> 
    Doporučené rozměry {{dimension}}x{{dimension}}px.`,
  personalDataInfo:
    'Tento údaj nebude dostupný nikde na webu. Slouží pro potřeby zpracování osobních údajů.',
  gdprDataInfo: 'Tento údaj nebude nikde zveřejněný, slouží jen pro účely GDPR.',
  textareaFormatInfo: 'Pro klikatelnou URL použijte tvar https://pretlak.com.',
  telephoneInput:
    'Slouží jen pro nás v případě řešení nějakého problému. Nebude se nikde veřejně zobrazovat.',
  salary:
    'Uveď nám prosím, jak si ceníš svůj čas. Chceme být transparentní a formovat trh tím správným směrem.',
  certification:
    'Pokud máš za sebou něco, co stojí za zmínku, napiš to teď nebo nikdy. Např. kurz vaření 😉',
  exportToCsvForPackages:
    'Export do CSV je možný pouze u nabídek typu Exkluziv a Prémium',
  hrUrl: 'Slouží k propojení s ATS / Recruitment systémem',
  packageExpireDate:
    'Platnost balíčku s 5 nabídkami je časově neomezená. Platíte za 4 nabídky (jedna je zdarma).',
  mediumPackageExpireDate:
    'Platnost balíčku s 10 nabídkami je časově neomezená. Platíte za 7 nabídek (3 jsou zdarma).',
}

const placeholder = {
  yourEmail: 'Tvůj e-mail',
  minimumChars: 'Minimálně {{count}} znaků',
  city: 'Začněte psát',
  cityShort: 'Vyberte město',
  aboutCompany: 'Základní informace o firmě, hodnoty, historie, úspěchy, však víte…',
  companyName: 'Začněte psát název firmy pro zobrazení výsledků...',
  fullName: 'Zadejte celé jméno',
  note: 'Zde napište poznámku',
  salaryNote:
    'Nabízíte bonus, ESOP, jiné finanční benefity, nebo je součástí platu variabilní složka?',
  aboutMe:
    'Pojď zaujmout potenciálního klienta – prodej se, uleť si, napiš, co děláš, co neděláš, s kým už jsi spolupracoval nebo jaká je tvoje oblíbená značka tenisek... Nezadávejte své telefonní číslo ani e-mail.',
  education:
    'Nepřeceňujeme ho ani nepodceňujeme. Necháme na tobě, jak s touto položkou naložíš.',
  portfolio:
    'Nyní máš prostor podrobněji popsat své projekty, na které jsi hrdý, nebo na které nejsi. Přidej referenční linky, emojis, vždyť víš...',
  countrySelect: 'Napr. Slovensko',
  candidateResponseModalText: 'Vaše zpráva, kterou odešleme uchazeči e-mailem',
  invoiceNote: 'Napr. číslo objednávky',
  hrUrl: 'Napr. https://pretlak.com',
  introductionText:
    'Pošlete úvodní signál! Můžete zde shrnout obecné informace o vás, o pozici nebo popsat svého ideálního kolegu. Nebo řekněte vtip?',
}

const label = {
  requirements: 'Požadavky',
  workMail: 'Pracovní e-mail',
  contactMail: 'Kontaktní e-mail',
  nameAndSurname: 'Jméno a příjmení',
  residenceCity: 'Město trvalého bydliště',
  birthYear: 'Rok narození',
  telephoneNumber: 'Telefonní číslo',
  invoiceNote: 'Poznámka na fakturu',
  hrUrl: 'Odkaz na ATS systém',
  introductionText: 'Na uvítanou',
}

const search = {
  placeholderJobs: 'Vyhledej job, firmu ...',
  placeholderFreelancers: 'Vyhledej freelancera ...',
  placeholderCompanies: 'Vyhledaj firmy ...',
  lastSearched: 'Naposledy hledané',
  lastSearchedMobile: 'Naposledy',
}

const shortcuts = {
  companyId: 'IČO',
  vatID: 'IČ-DPH (ne pro CZ firmy)',
  vatNumber: 'DIČ',
  postCode: 'PSČ',
  vat: 'DPH',
  forExample: 'Např.',
}

const footer = {
  text: 'Potřebujete poradit?',
}

module.exports = {
  actions,
  error,
  tooltip,
  placeholder,
  label,
  search,
  shortcuts,
  footer,
  availability: 'Dostupnost',
  new: 'Novinka',
  addVideoPlaceholder: 'Vložte link na vaše video',
  password: 'Heslo',
  more: 'Více',
  free: 'Zdarma',
  requiredField: 'Povinná data',
  wantReceiveEmailWeeklyOffers: 'Chci dostávat každý týden podobné nabídky e-mailem',
  somethingAboutMe: 'Tvoje šance vyniknout',
  basicInfoAboutMe:
    'Zkušenosti, motivace, báseň nebo důležitý link, vynikni po svém. Firmy to skutečně čtou a někdy se dokonce na základě toho rozhodují!',
  noOfferFound: 'Žádná nabídka nebyla nalezena.',
  noCompanyFound: 'Žádná firma nebyla nalezena.',
  noCompanyJobs: 'Firma nemá žádné otevřené nabídky.',
  loadMore: 'Načíst více',
  loginTitle: 'Přihlášení',
  loginMyself: 'Přihlásit se',
  loginYourself: 'Přihlaste se',
  loginYourselfLowercase: 'přihlaste se',
  great: 'Paráda!',
  forgetYourPassword: 'Zapomněli jste heslo?',
  forgottenPasswordDescription:
    'Nevadí, to se stává. Zadejte váš e-mail a my vám pošleme návod, jak heslo obnovit.',
  addProfile: 'Přidat profil',
  noRegistrationYet: 'Ještě nemáte své přihlašovací údaje?',
  noRegistrationYetFreelancer: 'Ješte nemáš vytvořený profil?',
  registerYourself: 'Zaregistrujte se',
  registerYourselfFreelancer: 'Zaregistruj se',
  moreInfo: 'Více informací',
  moreInfoShort: 'Více info',
  iAmInterested: 'Mám zájem',
  registerFreelancerProfile: 'Zaregistrujte si profil freelancera',
  form: {
    selectOption: 'Vyberte možnost',
    typeNewPassword: 'Zadejte nové heslo',
    typeNewPasswordAgain: 'Znovu zadejte nové heslo',
    saveNewPassword: 'Uložit nové heslo',
    typeNewEmail: 'Zadaj nový email',
    saveNewEmail: 'Uložit nový email',
  },
  toPageTop: 'Nahoru na stránku',
  aboutUs: 'O nás',
  continue: 'Pokračovat',
  talentBox: {
    text: 'Stovka uchazečů ihned k dispozici. Výběrový proces tak můžete začít okamžitě. Přístup k celé databázi životopisů na vyžádání.',
    freshCVsDatabase: `Čerstvá<br />
    databáze
    <br />
    životopisů`,
    freelancerCategoryWithCount: {
      marketers: '{{count}} Marketérů',
      designers: '{{count}} Designérů',
      developers: '{{count}} Developerů',
      creatives: '{{count}} Kreativců',
      nonTech: '{{count}} Non-tech',
    },
    price: 'Cena na vyžádání',
  },
  weRecommend: 'DOPORUČUJEME',
  availableNow: 'Dostupné ihned',
  favorite: 'Oblíbené',
  category: 'kategorie',
  jobOffers: 'Pracovní nabídky',
  freelancers: 'Freelanceři',
  advantageousOffers: 'Výhodné nabídky',
  databaseOfCVs: 'Databáze životopisů',
  advantageousPackages: 'Výhodné balíky',
  publicProfile: 'Veřejný profil',
  companySubscriptionEdit: 'Upravit předplatné',
  companyInfo: 'Firemní údaje',
  addMissingCompanyInfo: 'Doplňte chybějící údaje o firmě v části',
  emailNotificationsSettings: 'Nastavení e-mailových notifikací',
  iWantCandidateMailNotifications:
    'Chci dostávat e-mail s upozorněním na nového uchazeče',
  iWantOffersMailNotifications: 'Chci dostávat informace o stavu mé nabídky',
  iWantInoAboutProductsStatus: 'Chci dostávat novinky a tipy na zlepšení profilu.',
  iWantAllMailNotifications: 'Chci dostávat všechny e-maily.',
  telephoneNumberPrefix: '+420',
  invoices: 'Faktury',
  noInvoicesYet: 'Zatím pro vás nemáme vystavené žádné faktury',
  publishToSocialNetwork: 'Publikovat na sociální sítě',
  cardPayment: 'Platba kartou',
  publishOfferImmediately: 'Zveřejnění nabídky ihned',
  publishOfferAfterPayment: 'Zveřejnění nabídky po úhradě',
  willDoImmediately: 'Vykonáme ihned',
  viewImmediately: 'Zobrazení ihned',
  invoiceBankTransfer: 'Faktura – převodem na účet',
  companiesThatTrustedUsTitle: 'Firmy, které nám projevily důvěru a hledaly lidi u nás',
  summary: 'Celkem',
  summaryWithVAT: 'Celkem s DPH',
  summaryWithoutVAT: 'Celkem bez DPH',
  iAmOfferingJob: 'Nabízím práci',
  iAmLookingForJob: 'Hledám práci',
  tellUsWhyAreYouHere: 'Prozraď nám, proč jsi sem přišel?',
  paymentGateway: 'Platební brána',
  note: 'Poznámka',
  news: 'Novinky',
  queryModal: {
    text: 'Informace o uchazečích jsou u nás kvůli nařízení GDPR dostupné 12 měsíců od reakce na nabídku. Poté jsou automaticky smazány.',
    feedbackText: 'Napište nám vaši zkušenost a zpětnou vazbu. Děkujeme.',
  },
  successPaymentModal: {
    freelancerAddonSuccess: 'Vybranou službu zpracujeme nejpozději do 24 hodin.',
    addonColorText: 'Zvýraznění pracovní nabídky vlastní barvou',
    wasSuccessFullyDone: 'bylo úspěšně vykonané.',
    extraShareNote: 'Extra sdílení provedeme do 24 hodin.',
    topForDays: 'Topování na {{days}} dní',
    freelanceContent: {
      heading: 'Freelanceri jsou odemčeni',
      description: 'Každý týden obdržíte e-mailem nové freelancery. Vyberte kategorie.',
    },
    freelanceAddonContent: {
      top: 'topování',
      customColor: 'zvýraznění profilu vlastní barvo',
      socialShare: 'extra sdílení provedeme do 24 hodin',
    },
    addonContent: {
      weSentInvoiceToPay: 'Odeslali jsme vám fakturu k úhradě.',
      shareUpToThreeDays: 'Sdílení proběhne nejpozději do 3 dní.',
    },
    jobContent: {
      creditCardText:
        'Děkujeme! Do následujícího pracovního dne nabídku zkontrolujeme, zveřejníme na webu a přesdílíme na sociálních sítích.',
      promoCodeText:
        'Do nasledujúceho pracovného dňa ponuku skontrolujeme, zverejníme na webe a prezdieľame na sociálnych sieťach.',
      bankTransferText: `Pracovní nabídku zkontrolujeme, zveřejníme na webu a přesdílíme na
        sociálních sítích <strong>až po úhradě faktury</strong>. Prosíme o
        úhradu faktury, kterou naleznete ve svém e-mailu.`,
      cvDatabaseWillBeSend:
        'Databáza životopisov Vám bude zaslaná e-mailom po sprocesovaní.',
    },
    jobWithPackageContent: {
      creditCardText:
        'Zakoupení balíku nabídek a přidání nabídky bylo úspěšné. Do následujícího pracovního dne nabídku zkontrolujeme, zveřejníme na webu a přesdílíme na sociálních sítích.',
      bankTransferText: `Zakoupení balíku nabídek a přidání nabídky bylo úspěšné. Nabídku zkontrolujeme, zveřejníme na webu a přesdílíme na sociální sítě po úhradě faktury. Prosíme o úhradu faktury, kterou najdete na e-mailu.`,
      talentBoxWillBeSend: 'Talentbox vám bude zaslaný e-mailem po zprocesování.',
      activePromoCode: 'Aktivní výhodný balíček naleznete ve firemním profilu. Zobrazit.',
      successPayment: 'Platba proběhla úspěšně!',
    },
    packageContent: {
      creditCardText:
        'Děkujeme! Zakoupení balíku nabídek bylo úspěšné. Můžete si ho zobrazit v sekci „Výhodné balíky“. Při přidávání pracovní nabídky bude promokód z zakoupeného balíku automaticky vyplněn.',
      bankTransferText:
        'Zakúpenie balíka ponúk bolo úspešné. Môžete si ho zobraziť v časti výhodné balíky. Pri pridávaní pracovnej ponuky budete mať promokód zo zakúpeného balíka automaticky vyplnený. Prosíme o úhradu faktury, kterou najdete na e-mailu.',
    },
  },
  additionalCompanyModal: {
    tellUs: 'Ještě nám prozraďte',
    whatCategoryIsYourCompany: 'Do které kategorie patří vaše společnost?',
    whereDidYouHearAboutUs: 'Odkud jste se o nás dozvěděli?',
  },
  copyLink: 'Kopírovat odkaz',
  shareWithEmail: 'Sdílet e-mailem',
  checkWhatToDoForHigherOfferReach:
    'Zjistěte, co dělat, aby měla vaše nabídka co největší dosah. Klikněte sem',
  profileWasRemoved: 'Profil byl vymazán!',
  backToFreelancers: 'Zpět na freelancery',
  certificationTitle: 'Ocenění, certifikáty',
  portfolio: 'Portfolio',
  otherAttachment: 'Jiná příloha',
  portfolioUploadError:
    'Portfolio / Jiná příloha se nepodařilo nahrát. Prosím zkuste to znovu.',
  cvUploadError: 'Životopis se nepodařilo nahrát. Prosím zkuste to znovu.',
  education: 'Vzdělání',
  backToIntro: 'Zpět na úvod',
  almostDone: 'Téměř hotovo',
  recoverPasswordInstructionSentToMailCompany:
    'Právě jsme vám na zadaný e-mail poslali instrukce, jak si obnovit heslo. Věříme, že brzy budete opět s námi.',
  recoverPasswordInstructionSentToMail:
    'Právě jsme ti na zadaný e-mail poslali instrukce, jak si obnovit heslo. Věříme, že brzy budeš opět s námi.',
  yourPasswordSuccessfullyRestored:
    'Vaše heslo je úspěšně obnovené. Pokračujte přihlášením a užívejte Pretlak.',
  yourPasswordSuccessfullyRestoredFreelancer:
    'Tvé heslo je úspěšně obnovené. Pokračuj přihlášením a užívej Pretlak.',
  passwordReset: 'Reset hesla',
  hurayYouCanAddYourNewPassword:
    'Hurá. Teď můžete zadat vaše nové heslo a pokračovat přihlášením.',
  newPassword: 'Nové heslo',
  checkPassword: 'Zkontrolujte heslo',
  submitNewPassword: 'Potvrzení nového hesla',
  viewAll: 'Zobrazit vše',
  selectCandidate: 'Vybrat uchazeče',
  selectAndSendMail: 'Vybrat a odeslat e-mail',
  selectWithoutSendMail: 'Vybrat bez odeslání e-mailu',
  candidateApprovalModalMessage:
    'Vyhovuješ našim požadavkům a to znamená, že tě budeme brzy kontaktovat.',
  notes: 'Poznámky',
  typeNoteToCandidate: 'Zde napište své poznámky k uchazeči…',
  placeForYourPrivateNotes: 'Prostor pro vaše poznámky, které uvidíte jen vy.',
  rejectCandidate: 'Zamítnout uchazeče',
  rejectAndSendMail: 'Zamítnout a odeslat e-mail',
  rejectWithoutSendMail: 'Zamítnout bez odeslání e-mailu',
  rejectCandidateModalMessage:
    'Bohužel, nevyhovuješ našim požadavkům a do dalšího kola nepostupuješ. Přejeme mnoho úspěchů v dalším hledání.',
  promoCodeCopied: 'Promokód byl zkopírován.',
  activePackages: 'Aktivní balíky',
  active: 'Aktivní',
  used: 'Využitý',
  remains: 'Zůstává',
  expires: 'Expiruje',
  renewal: 'Obnovení',
  promoCode: 'Promokód',
  promoCodeValidUntil: 'Platnost promokódu',
  promoCodeName: 'Název promokódu',
  copyPromoCode: 'Zkopírovat promokód',
  packagesOffer: 'Nabídka balíků',
  displayMode: 'Režim zobrazení',
  accountSettings: 'Nastavení účtu',
  validity: 'Platnost',
  countOfOffers: 'Počet nabídek',
  back: 'Zpět',
  loading: 'Načítám',
  salary: 'Plat',
  city: 'Město',
  country: 'Země',
  positionName: 'Název pozice',
  companyName: 'Název firmy',
  pricing: 'Ceník',
  magazine: 'Magazín',
  statistics: 'Statistiky',
  buyItemAfterSubmitText:
    'Po potvrzení vám vystavíme fakturu v hodnotě {{price}}€ + DPH a vytvoříme balík s aktivním promokódem.',
  buyPackageModal: {
    success: {
      title: 'Děkujeme za zakoupení balíčku nabídek. Fakturu k úhradě jsme vám odeslali.',
      promocodeText: 'Promokód "{{code}}" je aktivní. Zobrazit výhodný balík',
    },
    content: {
      contactText: 'Potřebujete více info? Napište na',
      callUs: 'zavolejte',
    },
  },
  invoice: {
    name: 'Faktura',
    view: 'Zobrazit fakturu',
    price: 'Suma',
    created: 'Vytvořená',
    number: 'Číslo faktury',
    paid: 'Uhrazená',
    unpaid: 'Neuhrazená',
    afterExpiration: {
      one: 'Po splatnosti {{count}} den',
      other: 'Po splatnosti {{count}} dny',
      many: 'Po splatnosti {{count}} dní',
    },
  },
  hideProfile: 'Skrýt profil',
  publishProfile: 'Publikovat profil',
  editProfile: 'Upravit profil',
  showPublicProfile: 'Zobrazit veřejný profil',
  deleteProfile: 'Vymazat profil',
  submitDeleteProfileText:
    'Stisknutím tlačítka níže bude tvůj profil vymazán. Na věky věků. Opravdu to chceš udělat?',
  profile: 'Profil',
  myProfile: 'Můj profil',
  proSubscription: 'Pro členství',
  waitingForApproval: 'Čeká na schválení',
  findOutMore: 'Zjistit více',
  backToJobOffers: 'Zpět na pracovní nabídky',
  countOfCanditates: 'Počet uchazečů',
  added: 'Přidáno',
  updated: 'Aktualizováno',
  sharing: 'Sdílení',
  topping: 'Topování',
  extraSharing: 'Extra sdílení',
  topOffer: 'Topovat nabídku',
  topOfferDescription: 'Můžete vybrat více možností',
  jobTopOfferDescription:
    'Můžete vybrat více možností. Každá topovaná nabídka bude zařazena také do PPC kampaní.',
  noReactionsHr:
    'Vaše nabídka používá vlastní Odkaz na ATS systém / recruitment systém, ve kterém naleznete také životopisy uchazečů k této pracovní nabídce.',
  noReactionsToPostYet: 'Zatím nemáte žádné reakce na váš inzerát.',
  squeezTheMostFromOffer: 'Vyždímejte z vaší nabídky maximum a dopřejte jí něco navíc.',
  candidate: 'Uchazeč',
  date: 'Datum',
  countExpiredCandidates: '{{count}} exspirovaných uchazečů',
  infoAboutCandidatesWillRemovedAfterThreeMonths:
    'Informace o uchazečích jsou u nás kvůli nařízení GDPR dostupné <strong>12 měsíců</strong> od reakce na nabídku. Poté jsou automaticky smazány.',
  colorizeOffer: 'Zabarvení nabídky',
  package: 'Balík',
  discount: 'Sleva',
  offerPrice: 'Cena nabídky',
  buyPackageAndSaveMoney: 'Zakupte si balík nabídek a ušetřete!',
  cvModal: {
    vipListTitle: 'Staň se VIP uchazečem',
    vipListDescription:
      'Vyplň tyto tři údaje a automaticky tě zařadíme do naší VIP databáze uchazečů, kterou si firmy pravidelně prohlížejí. Je to zdarma!',
    gdpr: 'Vaše osobní údaje, získané prostřednictvím životopisů, mohou být sdíleny s dalšími zaměstnavateli hledajícími vhodné kandidáty na pracovní pozice na základě oprávněného zájmu, a to po dobu maximálně 12 měsíců. Pokud si nepřejete, abychom vaše osobní údaje nadále zpracovávali, máte právo kdykoliv požádat o jejich vymazání.',
  },
  duplicateUpsell: {
    title: 'Duplikujte nabídku!',
    text: 'Ušetřete čas. Nepište inzerát znovu, zdvojte ho.',
    cta: 'Chci duplikovat',
  },
  buyAddonModal: {
    contentTooltip:
      'Nabídka bude navrchu hlavní stránky, dokud ji nenahradí další. Datum přidání nabídky se aktualizuje.',
    extraShareText: 'Extra přesdílet na sociálních sítích',
    shareTooltip:
      'Nabídku znovu sdílíme na Facebooku, Instagramu, LinkedInu, kde máme více než 28 000 sledujících. Zobrazíme ji v týdenním Newsletteru, který pravidelně posíláme více než 7 000 uchazečům.',
    thankyou: {
      desc1:
        'Topování na 7 dní, Extra Share a zabarvení nabídky jsou aktivovány. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc2:
        'Topování na 14 dní, Extra Share a zabarvení nabídky jsou aktivovány. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc3:
        'Extra Share a zabarvení nabídky jsou aktivovány. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc4:
        'Topování na 7 dní a Extra Share jsou aktivovány. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc5:
        'Topování na 14 dní a Extra Share jsou aktivovány. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc6: 'Topování na 7 dní je aktivní. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc7: 'Topování na 14 dní je aktivní. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc8:
        'Extra Share je aktivován. Sdílení proběhne maximálně do 3 dnů. Ve vašem mailboxu naleznete fakturu. 🙌',
      desc9: 'Nabídka je zabarvená. Ve vašem mailboxu naleznete fakturu. 🙌',
    },
  },
  multiplationViews: '{{value}} VÍCE ZOBRAZENÍ',
  multiplationPercentageViews: '+{{value}}% ZOBRAZENÍ',
  highlightOffer: 'Zvýraznit nabídku',
  colorizeOfferWithValue: 'Zvýraznit nabídku - {{value}}',
  thankYou: 'Děkujeme',
  freelanceAddonThankYouTitle: 'Platba proběhla úspěšně!',
  addJob: 'Přidat job',
  editJob: 'Upravit job',
  duplicateJob: 'Duplikovat job',
  countCurrentJobOffers: {
    one: '{{count}} aktuální nabídka',
    other: '{{count}} aktuální nabídky',
    many: '{{count}} aktuálních nabídek',
  },
  company: 'Firma',
  companies: 'Firmy',
  companiesCount: {
    one: '{{count}} firma',
    other: '{{count}} firmy',
    many: '{{count}} firem',
  },
  jobs: 'Joby',
  offersCount: {
    one: '{{count}} ponuka',
    other: '{{count}} ponuky',
    many: '{{count}} ponuk',
  },
  jobsCount: {
    one: '{{count}} job',
    other: '{{count}} joby',
    many: '{{count}} jobů',
  },
  freelancersCount: {
    one: '{{count}} freelancer',
    other: '{{count}} freelanceři',
    many: '{{count}} freelanceřů',
  },
  notFoundTitle: 'Narazil jsi na ztracené místo.',
  selectPaymentMethod: 'Vyberte způsob platby',
  maximumMbSize: 'Maximální velikost {{size}}',
  file: 'soubor',
  insert: 'Vkládejte',
  paymentDue: 'Splatnost',
  buySuitablePackage: 'Koupit výhodný balík',
  iAgreeWith: {
    start1: 'Souhlasím se',
    subject1: 'zpracováním osobních údajů',
    purpose1:
      'pro účely dostávání exkluzivních akčních nabídek a jiných novinek z Pretlaku.',
    start2: 'Souhlasím s',
    subject2: 'podmínkami používání',
    purpose2: 'za účelem vytvoření osobního profilu na našem webu.',
    purpose3: 'za účelem pomoci při hledání podobné pracovní pozice.',
    end1: 'webu Prelak',
  },
  aboutCompany: 'O firmě',
  companyHasNoFreePositions: 'Tato společnost momentálně nemá žádné volné pozice.',
  buySummaryTooltip:
    'Databáze životopisů ihned k dispozici. Výběrový proces tak můžete začít okamžitě.',
  permanentEmployment: 'Hlavní pracovní poměr',
  invoicing: 'Fakturace (s.r.o., živnost, ...)',
  paymentInfo: 'Platební údaje',
  paymentEmail: 'Fakturační e-mail',
  weOffer: 'Nabízíme',
  contact: 'Kontakt',
  viewsCount: 'Počet zobrazení',
  clickCount: 'Počet prokliků',
  type: 'Typ',
  dateAdded: 'Přidáno',
  dateUpdated: 'Aktualizováno',
  dateSignup: 'Registrován',
  action: 'Akce',
  largeAmount: 'velké množství',
  promocodeAvailableImmediately: 'Dostupný ihned',
  topBar: {
    companies: 'Objev přetlak freelancerů!',
    freelancers: 'Přidej se k {{count}} freelancerům i ty!',
    freelancersWithoutNumber: 'Přidej se k freelancerům i ty!',
    freelancersProStart: `Přejdi na `,
    freelancersProEnd: ` a získej víc!`,
    merch: 'Máme merch, o který jste si všichni říkali! Koupíš',
    merchUnderline: 'ZDE ZDE ZDE',
    merchMobile: 'Máme merch!',
    freelancersOffer: 'Pouze teď 25% sleva na roční PRO členství.',
    freelancersOfferCta: 'Klikněte zde.',
    freelancersOfferMobile: '-25 % na PRO členství',
  },
  freeCountLabel: '{{label}} zdarma',
  offer: {
    default: 'nabídka',
    one: '{{count}} nabídka',
    other: '{{count}} nabídky',
    many: '{{count}} nabídek',
  },
  yourCompanyProfileState: 'stav vašeho firemního profilu',
  state: 'Stav',
  year: 'rok',
  price: 'cena',
  priceAsk: 'Na vyžádání',
  priceAskTitle: `Mám zájem o {{package}} nekonečný balík`,
  days: {
    one: '{{count}} den',
    other: '{{count}} dny',
    many: '{{count}} dní',
  },
  companyLogo: 'Logo firmy',
  viewsWithValue: '{{value}} zobrazení',
  selectCategory: 'Vyberte kategorii',
  selectSkill: 'Vyberte úroveň',
  selectAvailability: 'Vyber dostupnost',
  changePositionNameNote: 'Název pozice lze změnit do 10 dnů od zveřejnění nabídky.',
  boostOnWeb: 'Topovat na webu na {{count}} dnů',
  categories: 'Kategorie',
  countOfCandidates: 'Počet uchazečů',
  offerHasBeenClosed: 'Nabídka byla uzavřena',
  suit: 'Vyhovuje',
  doesNotSuit: 'Nevyhovuje',
  attachments: 'Přílohy',
  cv: 'Životopis',
  other: 'Jiné',
  downloadCv: 'Stáhnout životopis',
  downloadPortfolio: 'Stáhnout portfolio',
  upsellTop: {
    title: 'Topování!',
    text: 'Získejte 3krát více zobrazení. Oslovte nové uchazeče.',
    cta: 'Chci topovat',
  },
  upsellExtend: {
    titleSingle: 'Pozor! Tato nabídka bude online už jen dnes!',
    titleFew: 'Pozor! Tato nabídka bude online už jen {{days}} dny!',
    titleMany: 'Pozor! Tato nabídka bude online už jen {{days}} dní!',
    text: 'Nepropásněte šanci získat ještě více reakcí a prodlužte její platnost o dalších {{days}} dní — nyní jen za {{price}} € bez DPH.',
    cta: 'Prodloužit nyní',
  },
  cookiesBanner: {
    heading: 'Kuk, Cookies!',
    text: `Věc se má tak, že náš web ukládá do tvého zařízení soubory cookie. Důvod k panice? Cosi. Cookies používáme jen k tomu, abychom si tě pamatovali a dali dohromady pár informací o tom, jakým způsobem používáš náš web. Díky tomu bude tvůj zážitek u nás ještě lepší. Tady najdeš`,
    textMobile:
      'Náš web ukládá do tvého mobilního zařízení soubory cookies. Ty používáme jen k tomu, abychom si tě pamatovali a dali dohromady pár informací o tom, jakým způsobem používáš náš web. To vše, pro tvůj lepší zážitek. Tady najdeš',
    agree: 'Povolit vše',
    disagree: 'Nepovolit',
    save: 'Uložit výběr',
    settings: 'Přizpůsobit',
    settingsHeading: 'Nastavení cookies',
    moreInfo: 'více informací',
    lessInfo: 'A zde méně informací',
    functional: 'Potřebné',
    required: 'Povinné',
    optional: 'Volitelné',
    analytics: 'Analytické',
    ad: 'Reklamní',
    duration: 'Platnost',
    description: 'Popis',
    processor: 'Zpracovatel',
    date: {
      year: 'rok',
      years: 'roky',
      month: 'měsíc',
      months: 'měsíce',
      days: 'dní',
      day: 'den',
    },
    detail: {
      general:
        'Cookies používáme k analýze údajů o tobě, našem návštěvníkovi, abychom zlepšili tvůj zážitek a zobrazovali ti personalizovaný obsah, neboť každý chce obsah na míru, no ne?',
      functional:
        'Soubory Cookie nám všem pomáhají vytvářet použitelné webové stránky tak, že umožňují jejich základní funkce, jako je navigace stránky a přístup k chráněným oblastem webových stránek. Ve zkratce řečeno - bez Cookies by to nešlo, takže je nelze zakázat.',
      analytics:
        'Analytické soubory Cookies nám pomáhají v tom lépe pochopit, jak s tebou komunikovat prostřednictvím sbírání informací. Samozřejmě anonymně.',
      ad: 'Používáme je ke sledování tvých preferencí, za účelem zacílení reklamy, nebo zobrazování marketingových a reklamních sdělení (i na stránkách třetích stran), které by tě mohly jako našeho návštěvníka zajímat. Vše v souladu s těmito preferencemi. Marketingové Cookies využívají také nástroje externích společností. K jejich použití však potřebujeme tvůj souhlas. Jdeš do toho?',
    },
    descriptions: {
      ccSettings: 'Slouží k uložení nastavení cookies.',
      session: 'Slouží ke správnému přihlášení uživatele.',
      cv: 'Slouží k předvyplnění údajů uživatele při odesílání CV.',
      freelancer: 'Slouží k předvyplnění údajů uživatele při kontaktování freelanceru.',
      visited: 'Slouží k označování videních uchazečů v profilu firmy.',
      gaBasic: 'ID používané k identifikaci uživatelů.',
      ga24: 'ID slouží k identifikaci uživatelů po dobu 24 hodin od poslední aktivity.',
      gaAds:
        'Používá Google AdSense k experimentům s efektivitou reklamny na webových stránkách využívajících jejich služby.',
      metaAds:
        'Používá společnost Meta k poskytování reklamních produktů, jako jsou nabídky v reálném čase od inzerentů třetích stran.',
    },
  },
  jobDescription: 'Náplň práce',
  salaryPerHour: 'Hodinovka',
  residence: 'Sídlo',
  from: 'od',
  bonus: {
    one: 'bonus',
    other: 'bonusů',
    many: 'bonusy',
  },
  ppcCampaign: 'PPC kampaň',
  onlineCampaign: 'PPC online kampaň',
  countOfCVs: 'Počet CV',
  iWantBoostPrice: 'Chci topovat - {{price}} € + DPH',
  iWantBoostPriceWithoutVat: 'Chci topovat - {{price}} €',
  iWantBoost: 'Chci topovat',
  companyCategoryName: {
    AGENCY: 'Agentury & studia',
    MEDIA: 'Média',
    TECH: 'Tech & Inovace',
    TELCO: 'Telco, finance, veřejná správa',
    CULTURE: 'Kultura & Neziskový sektor',
    OTHERS: 'Ostatní',
  },
  companySubcategoryName: {
    AUDIO_VIDEO: 'Audio & Video produkce',
    WEB_DESIGN_DEVELOPMENT: 'Web design & development',
    GRAPHICS_DESIGN: 'Grafický design',
    SOCIAL_MEDIA_CONTENT: 'Social Media & Content',
    PR_COMMUNICATION: 'PR a komunikace',
    EVENT: 'Event',
    PRINT_ADVERTISING: 'Tisk & výroba reklamy',
    MEDIA_AGENCY: 'Mediální agentura',
    HR: 'HR',
    FULLSERVICE: 'Fullservis',
    IT_PROJECTS_DEVELOPMENT: 'Vývoj IT projektů',
    TV: 'TV',
    RADIO: 'Radio',
    PRINT: 'Print',
    PUBLISHING_HOUSE: 'Vydavatelství',
    INDOOR_OUTDOOR: 'Indoor / Outdoor',
    STARTUPS: 'Startupy',
    E_COMMERCE: 'E-commerce',
    SAAS: 'Saas',
    GAMING: 'Gaming',
    BANKING: 'Bankovnictví',
    INSURANCE: 'Pojišťovnictví',
    TELECOMMUNICATIONS: 'Telekomunikace',
    GOVERNMENT: 'Veřejná správa',
    GASTRONOMY_HOSPITALITY: 'Gastro & Hotelnictví',
    CULTURE: 'Kultura',
    ART: 'Umění',
    CIVIC_ASSOCIATION: 'Občanské sdružení',
    NON_PROFIT_ORGANIZATIONS: 'Neziskové organizace',
    EDUCATION: 'Vzdělávání',
    CONSTRUCTIONS: 'Stavebnictví a reality',
    ARCHITECTURE_INTERIOR_DESIGN: 'Architektura a interiérový design',
    RETAIL: 'Retail',
    OTHER_CATEGORY: 'Jiná kategorie',
  },
  cvs: 'Životopisy',
  finished: 'Ukončeno',
  notUsed: 'Nepoužito',
  noCountryFound: 'Země nebyla nalezena',
  with: 's',
  exportTo: 'Export do {{shortcut}}',
  activeViews: 'Aktivní zobrazení',
  ads: {
    title: `REKLAMNÍ POLOCHY <br />PRETLAK ADS!`,
    price: `Cena od 250 € <span class="addon">+ DPH</span>`,
    views: '{{count}} zobrazení webu za měsíc',
    social: '{{count}} fanoušků na sociálních sítích',
    newsletter: '{{count}} odběratelů newsletteru',
    description: `Zviditelněte svou značku přes naše reklamní plochy, které cílí přesně tam, kam potřebujete. Nabízíme různé <strong>webové formáty, sociální sítě nebo newsletter</strong>.`,
    cta: 'Zobrazit ceník',
  },
  hotCompany: {
    label: '🔥 HOT',
    company: 'firma',
    text1:
      'Buďte HOT a získejte více! Využijte výhody, které vaši firmu dostanou do popředí. Pro více informací nám',
    email: 'napište',
    text2: 'nebo zavolejte na',
  },
  discountValue: 'Sleva -{{value}}%',
  answer: 'Odpověď',
  dateDays: {
    zero: 'už jen dnes!',
    one: 'ještě {{date}} den',
    few: 'ještě {{date}} dny',
    many: 'ještě {{date}} dnů',
  },
}
